import { isEqual } from "lodash"
import * as Sentry from "@sentry/browser"

import { VoiceoverTick } from "./JungContext"

import unblockerFile from "./silence.mp3"

const VOICEOVER_BASE_URL = process.env.GATSBY_VOICEOVER_CONTENT_BASE_URL
const VOICEOVER_LOCALE = "en_GB"
const VOICEOVER_GAIN = 0.4
const VOICEOVER_LOAD_TIMEOUT = 10000

export class VoiceoverPlayer {
    private started = false

    private audioEl: HTMLAudioElement | null = null
    private removeAutoRestart: (() => void) | null = null
    private currentVoiceover: VoiceoverTick | null = null

    // prettier-ignore
    constructor(private broadcastIdentifier: string) { }

    start(): Promise<void> {
        if (this.started) return Promise.resolve()

        const audioEl = new Audio()
        audioEl.crossOrigin = "anonymous"
        audioEl.volume = VOICEOVER_GAIN

        this.audioEl = audioEl
        audioEl.src = unblockerFile
        return audioEl.play().then(() => {
            if (this.audioEl === audioEl) {
                // Successfully started.
                this.started = true
                this.captureLoadErrors()
            } else {
                // Superceded by a second attempt; discard this element.
                audioEl.pause()
                audioEl.removeAttribute("src")
                audioEl.load()
            }
        })
    }

    stop(): void {
        this.audioEl?.pause()
        this.audioEl?.removeAttribute("src")
        this.audioEl?.load()
        this.started = false
    }

    setVolume(vol: number): void {
        if (!this.audioEl) return
        this.audioEl.volume = vol
    }

    updateVoiceover(voiceover: VoiceoverTick | undefined, currentTime: number): void {
        if (
            this.started &&
            this.audioEl &&
            voiceover &&
            voiceover.activatedAt <= currentTime &&
            !this.isCurrentVoiceover(voiceover)
        ) {
            // There should be a voiceover but we're not playing it
            const startTime = currentTime - voiceover.activatedAt
            this.audioEl.pause()
            this.currentVoiceover = voiceover
            this.loadAndStartCurrentVoiceover(startTime)
        } else if (!voiceover && this.currentVoiceover) {
            console.log("voiceover content clear")
            // There should not be a voiceover but we're playing one
            this.audioEl?.pause()
            this.audioEl?.removeAttribute("src")
            this.audioEl?.load()
            this.currentVoiceover = null
            this.removeAutoRestart?.()
            this.removeAutoRestart = null
        }
    }

    private loadAndStartCurrentVoiceover(startTime: number) {
        if (!this.audioEl || !this.currentVoiceover)
            throw new Error("Trying to load a voiceover when audio element has not started")
        const voiceover = this.currentVoiceover
        const contentUrl = `${VOICEOVER_BASE_URL}/${voiceover.content}-${VOICEOVER_LOCALE}-${voiceover.section}.mp3`
        console.log("voiceover content start", voiceover, contentUrl, startTime)
        const onLoadedMetadata = new Promise<boolean>((res) =>
            this.audioEl?.addEventListener(
                "loadedmetadata",
                () => {
                    if (voiceover !== this.currentVoiceover || !this.audioEl) return // Removed before it had a chance to load

                    // Clamp to just below duration, as Safari will simply start from the beginning
                    // if currentTime is set to a value larger than the duration.
                    this.audioEl.currentTime = Math.min(startTime / 1000, this.audioEl.duration - 0.1)
                    this.audioEl.play()
                    this.removeAutoRestart = autoRestartOnPause(this.audioEl)
                    res(true)
                },
                { once: true }
            )
        )
        const onLoadTimeout = new Promise<boolean>((res) => setTimeout(() => res(false), VOICEOVER_LOAD_TIMEOUT))
        this.audioEl.src = contentUrl

        Promise.race([onLoadedMetadata, onLoadTimeout]).then((loadedWithoutTimeout) => {
            if (!loadedWithoutTimeout) {
                Sentry.withScope((scope) => {
                    scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                    scope.setExtra("context", { currentVoiceover: voiceover })
                    Sentry.captureMessage("Voiceover load timeout")
                })
            }
        })
    }

    private captureLoadErrors() {
        this.audioEl?.addEventListener(
            "error",
            (err) => {
                Sentry.withScope((scope) => {
                    scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                    scope.setExtra("context", { currentVoiceover: this.currentVoiceover, error: err.message })
                    Sentry.captureMessage("Voiceover load error")
                })
            },
            { once: true }
        )
    }

    private isCurrentVoiceover(voiceover: VoiceoverTick) {
        return isEqual(voiceover, this.currentVoiceover)
    }
}

function autoRestartOnPause(el: HTMLMediaElement) {
    const onPause = async () => {
        // Keep running even if audio pauses for some reason.
        // Pausing has been observed to happen on OS X Big Sur when a Bluetooth device is disconnected.
        // However, a pause is also emitted just before "ended" when the stream ends, so we must detect if this was a non-end pause
        const ended = new Promise((res) => el.addEventListener("ended", () => res(true), { once: true }))
        const didNotEnd = new Promise((res) => setTimeout(() => res(false), 500))
        const didEnd = await Promise.race([ended, didNotEnd])
        if (!didEnd) {
            console.log("Voiceover audio element paused. Attempting to play again.")
            el.play()
        }
    }
    el.addEventListener("pause", onPause)
    return () => {
        el.removeEventListener("pause", onPause)
    }
}
