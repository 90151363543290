module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wavepaths","short_name":"Wavepaths","start_url":"/","background_color":"#6A857F","theme_color":"#6A857F","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6b68fa844d93cd7362ada6097db023a3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-jung-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b8a58efc217245eea60eaca52552ece1@o396114.ingest.sentry.io/6213215","maxBreadcrumbs":100,"environment":"staging","enabled":true},
    },{
      plugin: require('../plugins/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"a0b1459a4bc94b7f5b39a27c7acce9ce","pageViews":"all"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
