import React from "react"
import { TunnelProvider } from "react-tunnels"

import JungHTMLWrapper from "./JungHTMLWrapper"

export const wrapPageElement = ({ element }) => {
    return (
        <TunnelProvider>
            <>
                <JungHTMLWrapper />
                {element}
            </>
        </TunnelProvider>
    )
}
