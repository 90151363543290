import React, { useContext, useState, useEffect } from "react"
import { set, isNumber, throttle } from "lodash"
import { AudioContext } from "standardized-audio-context"
import JungMetadataConnection from "./JungMetadataConnection"
import JungAudioStreamPlayer from "./JungAudioStreamPlayer"
import { isIOS, isSafari } from "./useJungAudioSupport"
import { VoiceoverPlayer } from "./VoiceoverPlayer"

const TICK_SMOOTHING = 0.99
const FIRST_FADE_IN_TIME = 8
const SUBSEQUENT_FADE_IN_TIME = 4
const FADE_OUT_TIME = 1.5
const PRELUDE_GRACE_PERIOD = 20

export enum SessionState {
    PLANNED = "planned",
    PRELUDE = "prelude",
    MAIN_PHASE = "mainPhase",
    POSTLUDE = "postlude",
    ENDED = "ended",
}

export type MusicAttributes = { [attrName: string]: number }

export type VoiceoverTick = {
    content: string
    section: string
    activatedAt: number
}

export interface RunningBroadcastTick {
    sessionState: SessionState
    timeUntilStart: number
    absoluteTime: number
    effectiveTime: number
    timeSinceInit: number
    wallClockTime: number
    preludeDuration: number
    sessionDuration: number
    postludeDuration: number
    presetVolume: number
    contentStage: number
    musicAttributes: MusicAttributes
    voiceover?: VoiceoverTick
    connectedUserCount: number
}

export interface NonRunningBroadcastTick {
    sessionState: SessionState
    timeUntilStart: number
    preludeDuration: number
    sessionDuration: number
    postludeDuration: number
}

export type SessionTick = RunningBroadcastTick | NonRunningBroadcastTick

export const isVolumeAdjustable = (): boolean => {
    // https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html
    return !isIOS()
}

let audioCtx,
    currentPlayer,
    currentVoiceoverPlayer: VoiceoverPlayer | undefined,
    volumeGain,
    voiceoverPlayer,
    metadataConnection,
    first = true

export const setupJung = (): void => {
    try {
        audioCtx = new AudioContext()
        console.log("made", audioCtx)
    } catch (e) {
        console.error(e)
        audioCtx = new window.webkitAudioContext()
    }
    console.log("sr", audioCtx.sampleRate)
    volumeGain = audioCtx.createGain()
    volumeGain.gain.value = 1
    volumeGain.connect(audioCtx.destination)
}

export const getAudioContext = () => {
    return audioCtx
}

function updateVoiceover(voiceover: VoiceoverTick | undefined, currentTime: number) {
    if (currentVoiceoverPlayer) {
        currentVoiceoverPlayer.updateVoiceover(voiceover, currentTime)
    }
}

function connectToMetadataStream(stream, { user, anonymousToken }) {
    if (metadataConnection) {
        if (metadataConnection.session === stream) {
            return
        } else {
            metadataConnection.close()
        }
    }
    metadataConnection = new JungMetadataConnection(stream, user, anonymousToken)
    ctx._contentStageOffset = null
    ctx.contentStage = null
    metadataConnection.on("tick", (tick: SessionTick) => {
        ctx.sessionState = tick.sessionState
        ctx.timeUntilStart = tick.timeUntilStart
        ctx.sessionDuration = tick.sessionDuration
        // Add some time for preludes to kick in in Freud/Stern before allowing external prelude states to trigger
        ctx.preludeDuration = Math.max(0, tick.preludeDuration - PRELUDE_GRACE_PERIOD * 1000)
        ctx.postludeDuration = tick.postludeDuration

        if ("absoluteTime" in tick) {
            // narrows this check narrows tick to RunningBroadcastTick
            ctx.absoluteTime = tick.absoluteTime
            ctx.wallClockTime = tick.wallClockTime
            ctx.connectedUserCount = tick.connectedUserCount
            if (ctx._contentStageOffset === null || tick.timeSinceInit < ctx.timeSinceInit) {
                ctx._contentStageOffset = Math.floor(-(tick.contentStage || 0))
                ctx.contentStage = 0
            }
            ctx.timeSinceInit = tick.timeSinceInit
            ctx.presetVolume = exponentialSmooth(tick.presetVolume || 0.1, ctx.presetVolume)
            ctx.contentStage = (tick.contentStage || 0) + ctx._contentStageOffset
            updateVoiceover(tick.voiceover, tick.wallClockTime)
        } else {
            updateVoiceover(undefined, 0) // not sure this is necessary just trying to preserve original behavior - WP, 03.17.21
        }
        for (const listener of ctx._changeListeners) {
            listener()
        }
    })
}

function disconnectFromMetadataStream() {
    if (metadataConnection) {
        metadataConnection.close()
        metadataConnection = null
    }
}

function connectToAudioStream(stream, isInfinite) {
    currentPlayer = new JungAudioStreamPlayer(
        stream,
        first ? FIRST_FADE_IN_TIME : SUBSEQUENT_FADE_IN_TIME,
        FADE_OUT_TIME,
        isInfinite,
        !isSafari() && !isIOS,
        volumeGain,
        audioCtx
    )
    currentVoiceoverPlayer = new VoiceoverPlayer(stream)
    first = false
    return Promise.all([currentPlayer.start(), currentVoiceoverPlayer.start()])
}

function disconnectFromAudioStream() {
    if (currentPlayer) {
        const playerToStop = currentPlayer
        const voiceoverPlayerToStop = currentVoiceoverPlayer
        playerToStop.stop()
        return new Promise<void>((res) =>
            setTimeout(() => {
                playerToStop.dispose()
                voiceoverPlayerToStop?.stop()
                if (!currentPlayer || currentPlayer === playerToStop) {
                    // A new one hasn't started by this time, stop voiceover
                    currentPlayer = null
                }
                if (!currentVoiceoverPlayer || currentVoiceoverPlayer === voiceoverPlayerToStop) {
                    currentVoiceoverPlayer = undefined
                }
                res()
            }, FADE_OUT_TIME * 1000)
        )
    } else {
        return Promise.resolve()
    }
}

type Listener = () => void

export interface JungCtx {
    timeUntilStart: number
    absoluteTime: number
    presetVolume: number
    contentStage: number | null
    wallClockTime: number
    timeSinceInit: number
    sessionDuration: number
    preludeDuration: number
    postludeDuration: number
    connectedUserCount: number
    session: null
    state: string
    isFullScreen: boolean
    audioState: string
    masterVolume: number
    parameters: any
    sessionState: SessionState
    isInfinite: boolean
    _contentStageOffset: null | number
    _changeListeners: any[]
    _stateChangeListeners: any[]
    _fullScreenListeners: any[]
    _audioStateChangeListeners: any[]
    onChange: any
    onStateChange: (listener: Listener) => any
    onFullScreenChange: (listener: (isFullScreen: boolean) => void) => any
    onAudioStateChange: (listener: Listener) => any
    setSession: any
    canAutoplayAudio: () => any
    initializeAudio: () => any
    isAudioInitialized: () => boolean
    updateParameter: any
    audioOff: any
    increaseContentStageOffset: any
    setState: any
    setFullScreen: (fullscreen: boolean) => void
    audioOn: any
    isAudioOn: () => boolean
    isAudioOff: () => boolean
    setMasterVolume: (vol: number) => any
    sendFeedback: (yay: boolean, feedback: string) => void
    advanceFromPrelude: () => void
}

const ctx: JungCtx = {
    sessionState: SessionState.PLANNED,
    isInfinite: false,
    timeUntilStart: 0,
    absoluteTime: 0,
    presetVolume: 0,
    contentStage: 0,
    wallClockTime: 0,
    timeSinceInit: 0,
    sessionDuration: 0,
    preludeDuration: 0,
    postludeDuration: 0,
    connectedUserCount: 0,
    session: null,
    state: "orbFullView",
    isFullScreen: false,
    audioState: "notInitialized",
    masterVolume: 1,
    parameters: {
        outerOrbBaseRadius: 0.95,
        outerOrbShadingMasterWeight: 0.0,
        outerOrbRadiusVolumeEffect: 0.8,
        outerOrbRadiusDistortionSize: 0.5,
        outerOrbRadiusDistortionAmplitude: 0.025,
        outerOrbRadiusDistortionSpeed: 0.25,
        outerOrbColorDistortion: 0.0, // NOTE: Ignored by shader at this time
        outerOrbRimStrength: 0.0, // NOTE: Ignored by shader at this time
        outerOrbRefractionAmount: 1.0, // NOTE: Ignored by shader at this time
        outerOrbEmissiveDampeningDistance: 1.1,
        outerOrbEmissiveDampeningStrength: -9,
        orbBackgroundHueRotationSchedule: { rotate: 30, pause: 30 },
        orbBackgroundHueRotationAmount: 30,
        orbBackgroundColorStop2Range: [0.25, 0.35],
        orbBackgroundColorStop2ChangeSpeed: 0.1,
        orbBackgroundColorStop3Range: [0.45, 0.55],
        orbBackgroundColorStop3ChangeSpeed: 0.11,
        innerCircleRadiusOffset: 0,
        innerCircleShadingMasterWeight: 1.0,
        innerCircleColorStop2: 0.6,
    },
    _contentStageOffset: null,
    _changeListeners: [],
    _stateChangeListeners: [],
    _fullScreenListeners: [],
    _audioStateChangeListeners: [],
    onChange: (listener, throttleTime) => {
        const throttledListener = isNumber(throttleTime) ? throttle(listener, throttleTime) : listener
        ctx._changeListeners.push(throttledListener)
        throttledListener()
        return () => ctx._changeListeners.splice(ctx._changeListeners.indexOf(throttledListener), 1)
    },
    onStateChange: (listener) => {
        ctx._stateChangeListeners.push(listener)
        listener()
        return () => ctx._stateChangeListeners.splice(ctx._stateChangeListeners.indexOf(listener), 1)
    },
    onFullScreenChange: (listener) => {
        ctx._fullScreenListeners.push(listener)
        listener(ctx.isFullScreen)
        return () => ctx._fullScreenListeners.splice(ctx._fullScreenListeners.indexOf(listener), 1)
    },
    onAudioStateChange: (listener) => {
        ctx._audioStateChangeListeners.push(listener)
        listener()
        return () => ctx._audioStateChangeListeners.splice(ctx._audioStateChangeListeners.indexOf(listener), 1)
    },
    setSession: async (
        newSession,
        { user = null, anonymousToken = null, forcingAutoPlay = false, isInfinite = false } = {}
    ) => {
        if (newSession !== ctx.session) {
            disconnectFromMetadataStream()
            ctx.session = newSession
            ctx.isInfinite = isInfinite
            ctx.timeUntilStart = 0
            ctx.absoluteTime = 0
            ctx.wallClockTime = 0
            ctx.timeSinceInit = 0
            ctx.sessionDuration = 0
            ctx.preludeDuration = 0
            ctx.postludeDuration = 0
            ctx.connectedUserCount = 0
            ctx.sessionState = SessionState.PLANNED
            for (const listener of ctx._changeListeners) {
                listener()
            }
            for (const listener of ctx._stateChangeListeners) {
                listener()
            }
            connectToMetadataStream(newSession, { user, anonymousToken })

            if (ctx.isAudioOn()) {
                if (ctx.canAutoplayAudio() || forcingAutoPlay) {
                    disconnectFromAudioStream()
                    await connectToAudioStream(ctx.session, ctx.isInfinite)
                    if (currentPlayer) {
                        // not stopped already while waiting
                        ctx.audioState = "on"
                        for (const listener of ctx._audioStateChangeListeners) {
                            listener()
                        }
                        currentPlayer.on("externalPause", () => ctx.audioOff(), { once: true })
                    }
                } else {
                    ctx.audioOff()
                }
            }
        }
    },
    updateParameter: (paramName, paramValue) => {
        ctx.parameters = { ...set(ctx.parameters, paramName, paramValue) }
        for (const listener of ctx._changeListeners) {
            listener()
        }
    },
    increaseContentStageOffset: () => {
        if (ctx._contentStageOffset !== null) {
            ctx._contentStageOffset++
        }
    },
    setState: (newState) => {
        ctx.state = newState
        for (const listener of ctx._stateChangeListeners) {
            listener()
        }
    },
    setFullScreen: (fullScreen: boolean): void => {
        ctx.isFullScreen = fullScreen
        for (const listener of ctx._fullScreenListeners) {
            listener(ctx.isFullScreen)
        }
    },
    audioOn: async ({ user, anonymousToken }) => {
        ctx.initializeAudio()
        if (!ctx.session) {
            throw new Error("Cannot start audio because no session has been set")
        }
        if (ctx.audioState === "off" || ctx.audioState === "notInitialized") {
            ctx.audioState = "starting"
            for (const listener of ctx._audioStateChangeListeners) {
                listener()
            }

            connectToMetadataStream(ctx.session, { user, anonymousToken })
            await connectToAudioStream(ctx.session, ctx.isInfinite)
            if (currentPlayer) {
                // Not stopped already while waiting
                ctx.audioState = "on"
                for (const listener of ctx._audioStateChangeListeners) {
                    listener()
                }
                currentPlayer.on("externalPause", () => ctx.audioOff(), { once: true })
            }
        }
    },
    audioOff: async () => {
        if (ctx.audioState === "on" || ctx.audioState === "starting") {
            ctx.audioState = "stopping"
            for (const listener of ctx._audioStateChangeListeners) {
                listener()
            }

            await disconnectFromAudioStream()
            if (!currentPlayer) {
                // Another one not started while waiting
                ctx.audioState = "off"
                for (const listener of ctx._audioStateChangeListeners) {
                    listener()
                }
            }
        }
    },
    isAudioOn: () => {
        return ctx.audioState === "on" || ctx.audioState === "starting"
    },
    isAudioOff: () => {
        return ctx.audioState === "off" || ctx.audioState === "stopping" || ctx.audioState === "notInitialized"
    },
    isAudioInitialized: () => {
        return ctx.audioState !== "notInitialized"
    },
    canAutoplayAudio: () => {
        // In order for autoplay to work the AudioContext must be initialised, but also if using Safari
        // media elements will never be allowed to autoplay; an interaction always required
        return ctx.isAudioInitialized() && !isSafari() && !isIOS()
    },
    initializeAudio: () => {
        audioCtx.resume().then(() => {
            if (ctx.audioState === "notInitialized") {
                ctx.audioState = "off"
                for (const listener of ctx._audioStateChangeListeners) {
                    listener()
                }
            }
        })
    },
    setMasterVolume: (vol) => {
        ctx.masterVolume = vol
        if ((isSafari() || isIOS()) && currentPlayer) {
            currentPlayer.audioSrcElement.volume = vol
            if (voiceoverPlayer) {
                voiceoverPlayer.setVolume(vol)
            }
        }
        if (volumeGain) {
            volumeGain.gain.value = Math.max(0, Math.min(1, vol ** 2))
        }
        for (const listener of ctx._audioStateChangeListeners) {
            listener()
        }
    },
    sendFeedback: (yay, feedback = "") => {
        if (metadataConnection) {
            metadataConnection.sendFeedback({ yay, feedback })
        }
    },
    advanceFromPrelude: () => {
        if (metadataConnection) {
            metadataConnection.advanceFromPrelude()
        }
    },
}

function exponentialSmooth(newVal, oldVal, factor = TICK_SMOOTHING) {
    const smoothed = (1 - factor) * newVal + factor * oldVal
    if (Math.abs(smoothed - newVal) < 0.001) {
        // After reaching an epsilon just use the new val
        return newVal
    } else {
        return smoothed
    }
}

export const JungContext = React.createContext(ctx)

// Hacky way to provide context and force a re-render when ctx changes
export const JungState = React.createContext(ctx)

export const JungProvider = ({ children }) => {
    const jungContext = useContext(JungContext)

    // note: this component is a hacky way to push changes
    // when the jungContext changes
    const [state, setState] = useState(jungContext)

    useEffect(() => {
        const unSubParam = jungContext.onChange(() => setState({ ...jungContext }), 200)
        const unSubState = jungContext.onStateChange(() => setState({ ...jungContext }))
        const unSubAudio = jungContext.onAudioStateChange(() => setState({ ...jungContext }))
        return () => {
            unSubParam()
            unSubState()
            unSubAudio()
        }
    }, [jungContext])

    return <JungState.Provider value={state}>{children}</JungState.Provider>
}

export const useAudioInit = (): (() => any) => {
    return ctx.initializeAudio
}
