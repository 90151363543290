/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "whatwg-fetch"
import "xmlhttprequest"
import "./global.scss"

import { fetch as fetchPolyfill } from "whatwg-fetch"

global.fetch = fetchPolyfill
