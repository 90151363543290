import { useEffect, useState } from "react"
import { detect } from "detect-browser"
import InApp from "detect-inapp"

const inapp =
    typeof window !== "undefined"
        ? new InApp(window.navigator.userAgent || window.navigator.vendor || window.opera)
        : undefined
const browser = detect()

export function isSafari() {
    return browser.name === "safari" || browser.name === "ios"
}

export function isIOS() {
    // Includes a workaround for iOS 13+ on iPad, which claims it's "Mac OS"
    return (
        typeof navigator !== "undefined" &&
        (/iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1))
    )
}

export function isInApp() {
    return inapp.isInApp
}

export const useJungAudioSupport = () => {
    let [support, setSupport] = useState("loading")

    useEffect(() => {
        // Restriction can be lifted in fure iOS versions that ship these fixes:
        // https://bugs.webkit.org/show_bug.cgi?id=205687
        // https://bugs.webkit.org/show_bug.cgi?id=203293
        setSupport(!isIOS() || (isSafari() && !isInApp()) ? "supported" : "unsupported")
    }, [])

    return support
}
