import React, { useMemo, useEffect, useContext, useRef } from "react"
import { useThree, useFrame } from "react-three-fiber"
import { Vector2, Vector3, Vector4 } from "three"
import { hsluvToRgb, rgbToHsluv } from "hsluv"

import vertexShader from "./jung-vertex.glsl"
import fragmentShader from "./jung-fragment.glsl"
import { JungContext } from "./JungContext"
import { useSpring } from "react-spring"

const [baseOrbBackgroundHue12, baseOrbBackgroundSaturation12, baseOrbBackgroundLightness12] = rgbToHsluv([
    0.6470588235,
    0.1294117647,
    0.6117647059,
])
const [baseOrbBackgroundHue3, baseOrbBackgroundSaturation3, baseOrbBackgroundLightness3] = rgbToHsluv([
    0.6039215686,
    0.09803921569,
    0.9843137255,
])

const JungOrbVisualisation = () => {
    let { size, aspect, clock, camera, gl, scene } = useThree()
    let jungContext = useContext(JungContext)
    let frameRate = useRef("low")
    let currentSession = useRef(null)

    let uniforms = useMemo(
        () => ({
            time: { value: 0 },
            presetVolume: { value: 0 },
            resolution: {
                value: new Vector2(0, 0),
            },
            aspect: { value: 1 },

            jungPosition: { value: new Vector3(0, 0, 0) },

            outerOrbBaseRadius: { value: 0 },
            outerOrbShadingMasterWeight: { value: 0 },
            outerOrbRadiusVolumeEffect: { value: 0 },
            outerOrbRadiusDistortionSize: { value: 0 },
            outerOrbRadiusDistortionAmplitude: { value: 0 },
            outerOrbRadiusDistortionSpeed: { value: 0 },
            outerOrbColorDistortion: { value: 0 },
            outerOrbRimStrength: { value: 0 },
            outerOrbRefractionAmount: { value: 0 },
            outerOrbEmissiveDampeningDistance: { value: 0 },
            outerOrbEmissiveDampeningStrength: { value: 0 },
            innerCircleRadiusOffset: { value: 0 },

            orbBackgroundHueRotationSchedule: { rotate: 0, pause: 0 },
            orbBackgroundHueRotationAmount: { value: 0 },

            backgroundColor: {
                value: new Vector3(0, 0, 0),
            },
            outerOrbEmissiveColor: {
                value: new Vector3(0, 0, 0),
            },

            orbBackgroundColor1: {
                value: new Vector3().fromArray(
                    hsluvToRgb([baseOrbBackgroundHue12, baseOrbBackgroundSaturation12, baseOrbBackgroundLightness12])
                ),
            },
            orbBackgroundColorStop1: {
                value: 0.0,
            },
            orbBackgroundColor2: {
                value: new Vector3().fromArray(
                    hsluvToRgb([baseOrbBackgroundHue12, baseOrbBackgroundSaturation12, baseOrbBackgroundLightness12])
                ),
            },
            orbBackgroundColorStop2: {
                value: 0.3,
            },
            orbBackgroundColor3: {
                value: new Vector3().fromArray(
                    hsluvToRgb([baseOrbBackgroundHue3, baseOrbBackgroundSaturation3, baseOrbBackgroundLightness3])
                ),
            },
            orbBackgroundColorStop3: {
                value: 0.5,
            },
            orbBackgroundColor4: {
                value: new Vector3(0, 0, 0),
            },
            orbBackgroundColorStop4: {
                value: 1.0,
            },

            innerCircleColor1: {
                value: new Vector4(246 / 255, 195 / 255, 233 / 255, 0),
            },
            innerCircleColorStop1: {
                value: 0.0,
            },
            innerCircleColor2: {
                value: new Vector4(246 / 255, 195 / 255, 233 / 255, 0),
            },
            innerCircleColorStop2: {
                value: 0.6,
            },
            innerCircleColor3: {
                value: new Vector4(246 / 255, 195 / 255, 233 / 255, 0.1),
            },
            innerCircleColorStop3: {
                value: 1.0,
            },
            innerCircleShadingMasterWeight: { value: 1.0 },
        }),
        []
    )
    useEffect(() => {
        uniforms.resolution.value.set(size.width, size.height)
        uniforms.aspect.value = aspect
    }, [size, aspect, uniforms])

    let [position, setPosition] = useSpring(() => ({
        x: 0,
        y: 0,
        z: 0,
        config: (i, state) => ({
            duration: state === "enter" ? 3000 : 3000,
            mass: 500,
            tension: 500,
            friction: 500,
            easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
        }),
        onFrame: (v) => {
            uniforms.jungPosition.value.set(v.x, v.y, v.z)
        },
        onStart: () => (frameRate.current = "high"),
        onRest: () =>
            (frameRate.current =
                jungContext.state === "orbFullView" || jungContext.state === "orbSunset" ? "low" : "stopped"),
    }))

    let [innerRadiusOffset, setInnerRadiusOffset] = useSpring(() => ({
        progress: 0,
        config: (i, state) => ({
            duration: 1000,
        }),
        onFrame: ({ progress }) => {
            let maxRadiusOffset = 0.06
            let growthTime = 0.2 // shrinkTime is 1 - growthTime
            let value = 0
            if (progress < growthTime) {
                let growProgress = progress / growthTime
                value = maxRadiusOffset * easeOutCubic(growProgress)
            } else {
                let shrinkProgress = (progress - growthTime) / (1 - growthTime)
                value = maxRadiusOffset * (1 - easeOutCubic(shrinkProgress))
            }
            uniforms.innerCircleRadiusOffset.value = value
        },
    }))

    // Parameter-based changes (~10fps and only when parameters are changing)
    useEffect(() => {
        let unSubParams = jungContext.onChange(() => {
            uniforms.outerOrbBaseRadius.value = jungContext.parameters.outerOrbBaseRadius

            let stageMasterWeight = jungContext.parameters.outerOrbShadingMasterWeight
            let stageTransitionWeight = Math.abs(jungContext.contentStage - Math.round(jungContext.contentStage)) * 2
            let outerOrbShadingMasterWeight = stageMasterWeight + (1 - stageMasterWeight) * stageTransitionWeight
            uniforms.outerOrbShadingMasterWeight.value = outerOrbShadingMasterWeight

            uniforms.outerOrbRadiusVolumeEffect.value = jungContext.parameters.outerOrbRadiusVolumeEffect

            uniforms.outerOrbRadiusDistortionSize.value = jungContext.parameters.outerOrbRadiusDistortionSize

            uniforms.outerOrbRadiusDistortionAmplitude.value = jungContext.parameters.outerOrbRadiusDistortionAmplitude

            uniforms.outerOrbRadiusDistortionSpeed.value = jungContext.parameters.outerOrbRadiusDistortionSpeed

            uniforms.outerOrbColorDistortion.value = jungContext.parameters.outerOrbColorDistortion

            uniforms.outerOrbRimStrength.value = jungContext.parameters.outerOrbRimStrength

            uniforms.outerOrbRefractionAmount.value = jungContext.parameters.outerOrbRefractionAmount

            uniforms.outerOrbEmissiveDampeningDistance.value = jungContext.parameters.outerOrbEmissiveDampeningDistance

            uniforms.outerOrbEmissiveDampeningStrength.value = jungContext.parameters.outerOrbEmissiveDampeningStrength

            uniforms.presetVolume.value = jungContext.presetVolume

            uniforms.innerCircleShadingMasterWeight.value = jungContext.parameters.innerCircleShadingMasterWeight

            uniforms.innerCircleColorStop2.value = jungContext.parameters.innerCircleColorStop2

            if (currentSession.current !== jungContext.session) {
                console.log("session changed")
                setInnerRadiusOffset({ progress: 0, immediate: true })
                setInnerRadiusOffset({ progress: 1 })
                currentSession.current = jungContext.session
            }
        })
        let unSubState = jungContext.onStateChange(() => {
            let worldMid = new Vector3(0, 0, 0)
            worldMid
                .project(camera)
                .setComponent(
                    1,
                    jungContext.state === "orbFullView" ? 0 : jungContext.state === "orbSunset" ? -0.45 : -0.65
                )
                .unproject(camera)
            setPosition({ x: worldMid.x, y: worldMid.y, z: worldMid.z })
        })
        return () => {
            unSubParams()
            unSubState()
        }
    }, [jungContext, uniforms, camera, setPosition])

    // Per-frame changes (~60fps)
    useFrame(() => {
        uniforms.time.value = clock.getElapsedTime()

        // Screen background
        let backgroundHue = 268 + Math.sin(uniforms.time.value / 100) * 10
        let backgroundSaturation = 100
        let backgroundLightness = 3
        uniforms.backgroundColor.value.fromArray(hsluvToRgb([backgroundHue, backgroundSaturation, backgroundLightness]))

        // Emissive
        let outerOrbEmissiveHue = (backgroundHue + 90) % 360
        let outerOrbEmissiveSaturation = 10
        let outerOrbEmissiveLightness = 30
        uniforms.outerOrbEmissiveColor.value.fromArray(
            hsluvToRgb([outerOrbEmissiveHue, outerOrbEmissiveSaturation, outerOrbEmissiveLightness])
        )

        // Orb background
        let orbBackgroundHueRotation = calculateOrbBackgroundHueRotation(jungContext, uniforms.time.value)
        let orbBackgroundHue12 = (baseOrbBackgroundHue12 + orbBackgroundHueRotation) % 360
        let orbBackgroundHue3 = (baseOrbBackgroundHue3 + orbBackgroundHueRotation) % 360
        let orbBackgroundLightnessDelta =
            easeOutCubic(jungContext.parameters.outerOrbRadiusVolumeEffect * jungContext.presetVolume) * 20
        let orbBackgroundColor12 = hsluvToRgb([
            orbBackgroundHue12,
            baseOrbBackgroundSaturation12,
            Math.min(100, baseOrbBackgroundLightness12 + orbBackgroundLightnessDelta),
        ])
        let orbBackgroundColor3 = hsluvToRgb([
            orbBackgroundHue3,
            baseOrbBackgroundSaturation3,
            Math.min(100, baseOrbBackgroundLightness3 + orbBackgroundLightnessDelta),
        ])
        uniforms.orbBackgroundColor1.value.fromArray(orbBackgroundColor12)
        uniforms.orbBackgroundColor2.value.fromArray(orbBackgroundColor12)
        uniforms.orbBackgroundColor3.value.fromArray(orbBackgroundColor3)
        uniforms.orbBackgroundColor4.value.copy(uniforms.backgroundColor.value)

        let [colorStop2Min, colorStop2Max] = jungContext.parameters.orbBackgroundColorStop2Range
        let colorStop2Speed = jungContext.parameters.orbBackgroundColorStop2ChangeSpeed
        uniforms.orbBackgroundColorStop2.value =
            colorStop2Min +
            (colorStop2Max - colorStop2Min) * (Math.sin(uniforms.time.value * colorStop2Speed) / 2 + 0.5)

        let [colorStop3Min, colorStop3Max] = jungContext.parameters.orbBackgroundColorStop3Range
        let colorStop3Speed = jungContext.parameters.orbBackgroundColorStop3ChangeSpeed
        uniforms.orbBackgroundColorStop3.value =
            colorStop3Min +
            (colorStop3Max - colorStop3Min) * (Math.sin(uniforms.time.value * colorStop3Speed) / 2 + 0.5)
    })

    // Throttled render loop
    useFrame(({ gl, scene, camera }) => {
        // Doing nothing in three-fiber's render loop...
    }, 1)
    useEffect(() => {
        // ..and replacing with a manual timeout-based render loop instaead
        let running = true
        function animate() {
            if (!running) return
            if (frameRate.current !== "stopped") {
                gl.render(scene, camera)
            }
            if (frameRate.current === "high") {
                requestAnimationFrame(animate)
            } else {
                setTimeout(() => requestAnimationFrame(animate), 1000 / 30)
            }
        }
        animate()
        return () => {
            running = false
        }
    }, [gl, scene, camera])

    return (
        <mesh>
            <planeBufferGeometry attach="geometry" args={[2, 2]} />
            <shaderMaterial
                attach="material"
                vertexShader={vertexShader}
                fragmentShader={fragmentShader}
                uniforms={uniforms}
            />
        </mesh>
    )
}

function calculateOrbBackgroundHueRotation(jungContext, time) {
    let {
        orbBackgroundHueRotationSchedule: rotationSchedule,
        orbBackgroundHueRotationAmount: rotationAmount,
    } = jungContext.parameters
    let oneFullRotationTime = rotationSchedule.pause + rotationSchedule.rotate
    let fullRotationsCompleted = Math.floor(time / oneFullRotationTime)
    let currentRotationProgress =
        Math.max(0, time - fullRotationsCompleted * oneFullRotationTime - rotationSchedule.pause) /
        rotationSchedule.rotate
    return (fullRotationsCompleted + currentRotationProgress) * rotationAmount
}

function easeOutCubic(t) {
    return --t * t * t + 1
}

export default JungOrbVisualisation
