const Sentry = require("@sentry/react")
const Integrations = require("@sentry/integrations")

exports.onClientEntry = function (_, pluginParams) {
    if (pluginParams === undefined) {
        return
    }

    const integrations = [new Integrations.Offline()]

    Sentry.init({
        environment: process.env.NODE_ENV || "development",
        // eslint-disable-next-line no-undef
        release: __SENTRY_RELEASE__,
        // eslint-disable-next-line no-undef
        dsn: __SENTRY_DSN__,
        ...pluginParams,
        integrations,
    })

    Sentry.addGlobalEventProcessor((event) => {
        event.sdk = {
            ...event.sdk,
            name: "sentry.javascript.gatsby",
            packages: [
                ...((event.sdk && event.sdk.packages) || []),
                {
                    name: "npm:@sentry/gatsby",
                    version: Sentry.SDK_VERSION,
                },
            ],
            version: Sentry.SDK_VERSION,
        }
        return event
    })
    window.Sentry = Sentry
}
