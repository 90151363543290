import io from "socket.io-client"
import EventEmitter from "events"

const METADATA_STREAM_BASE_URL = `${process.env.GATSBY_FREUD_BASE_URL}/broadcastMetadata`

class JungMetadataConnection extends EventEmitter {
    constructor(session, user, anonymousToken) {
        super()
        this.session = session
        this.socket = io(`${METADATA_STREAM_BASE_URL}/${session}`, {
            transports: ["websocket"],
        })
        this.socket.on("tick", (tick) => this.emit("tick", tick))
        // On initial connection, as well as reconnections, send user information
        this.socket.on("connect", () => {
            this.connectUser(user, anonymousToken)
        })
        const connector = setInterval(() => {
            if (this.socket.closed) {
                clearInterval(connector)
            } else if (!this.socket.connected) {
                this.socket.open()
            } else {
                clearInterval(connector)
            }
        }, 1000)
    }

    connectUser(user, anonymousToken) {
        if (user) {
            this.socket.emit("setUser", {
                type: "firebase",
                firebaseUserId: user.uid,
                name: user.name,
                email: user.email,
            })
        } else {
            this.socket.emit("setUser", { type: "anonymous", anonymousToken })
        }
    }

    sendFeedback(feedback) {
        this.socket.emit("feedback", feedback)
    }

    advanceFromPrelude() {
        this.socket.emit("broadcastUserAdvanceFromPrelude")
    }

    close() {
        this.socket.close()
    }
}

export default JungMetadataConnection
