import Hls from "hls.js"
import { pick, partial, isNumber } from "lodash"
import EventEmitter from "events"

const AUDIO_STREAM_BASE_URL = process.env.GATSBY_JUNG_AUDIO_STREAM_URL

const NETWORK_STATS_REPORT_INTERVAL = 5 * 60 * 1000
const BUFFER_STALL_MEDIA_DEVICE_CHANGE_WAIT_PERIOD = 1 * 1000

const TRACED_AUDIO_EVENTS = [
    "abort",
    "canplay",
    "canplaythrough",
    "durationchange",
    "emptied",
    "ended",
    "error",
    "loadeddata",
    "loadedmetadata",
    "loadstart",
    "pause",
    "play",
    "playing",
    "progress",
    "ratechange",
    "seeked",
    "seeking",
    "stalled",
    "suspend",
    "waiting",
]
class JungAudioStreamPlayer extends EventEmitter {
    constructor(broadcastIdentifier, fadeInTime, fadeOutTime, isInfinite, canAutoplay, audioDest, audioCtx) {
        super()
        this.broadcastIdentifier = broadcastIdentifier
        this.fadeInTime = fadeInTime
        this.fadeOutTime = fadeOutTime
        this.isInfinite = isInfinite
        this.canAutoplay = canAutoplay
        this.audioCtx = audioCtx
        this.lastMediaDeviceChangeAt = null
        this._initAudioGraph(audioDest)
    }

    _initAudioGraph(audioDest) {
        this.audioSrcElement = document.createElement("audio")
        this.audioSrcElement.crossOrigin = "anonymous"

        this.audioSrc = this.audioCtx.createMediaElementSource(this.audioSrcElement)
        this.masterGain = this.audioCtx.createGain()

        this.audioSrc.connect(this.masterGain)
        this.masterGain.connect(audioDest)

        this.masterGain.gain.value = 0

        this._monitorMediaDeviceChanges()

        this.audioSrcElement.addEventListener("pause", () => this._reportPauseIfNotEnding())

        for (const evtName of TRACED_AUDIO_EVENTS) {
            this.audioSrcElement.addEventListener(evtName, () => {
                Sentry.addBreadcrumb({
                    category: "streaming",
                    data: { audioEvent: evtName },
                })
            })
        }
    }

    async start() {
        this.isPlaying = true
        let firstPlayPromise
        let playLoop = async (fadeIn) => {
            if (!this.isPlaying) return
            let [playPromise, stopPromise] = this._play(fadeIn)
            if (!firstPlayPromise) {
                firstPlayPromise = playPromise
            } else {
                // Other plays than the first can have their errors reported internally.
                playPromise.catch((e) => {
                    console.error(e)
                })
            }
            stopPromise.catch((err) => {
                console.log("Stream failure - retrying in 1s", err)
                setTimeout(() => playLoop(false), 1000)
            })
            stopPromise.then(() => {
                console.log("Stream ended")
                if (this.isInfinite) {
                    console.log("Connecting again in 10s")
                    setTimeout(() => playLoop(true), 10000)
                }
            })
        }
        await playLoop(true)
        this.networkStats = []
        this.networkStatsReporterLoop = setInterval(() => this._reportNetworkStats(), NETWORK_STATS_REPORT_INTERVAL)
        return firstPlayPromise
    }

    stop() {
        this.isPlaying = false
        this.masterGain.gain.setValueAtTime(1, this.audioCtx.currentTime)
        this.masterGain.gain.linearRampToValueAtTime(0, this.audioCtx.currentTime + this.fadeOutTime)
        clearTimeout(this.networkStatsReporterLoop)
    }

    _play(fadeIn) {
        let playPromise
        let eventCleanUp = []
        let stopPromise = new Promise(async (stopRes, stopRej) => {
            if (fadeIn) {
                eventCleanUp.push(
                    onEvent(
                        this.audioSrcElement,
                        "canplay",
                        () => {
                            this.masterGain.gain.setValueAtTime(0, this.audioCtx.currentTime)
                            this.masterGain.gain.linearRampToValueAtTime(1, this.audioCtx.currentTime + this.fadeInTime)
                        },
                        { once: true }
                    )
                )
            }
            eventCleanUp.push(
                onEvent(
                    this.audioSrcElement,
                    "ended",
                    () => {
                        if (this.hls) {
                            this.hls.destroy()
                            this.hls = null
                        }
                        stopRes()
                    },
                    { once: true }
                )
            )
            eventCleanUp.push(onEvent(this.audioSrcElement, "error", stopRej, { once: true }))

            if (Hls.isSupported()) {
                const hlsLogger = (level, ...msg) => {
                    Sentry.addBreadcrumb({
                        category: "streaming",
                        data: { level, msg: msg.join(" ") },
                    })
                }

                this.hls = new Hls({
                    liveSyncDurationCount: 3,
                    initialLiveManifestSize: 3,
                    startLevel: 0,
                    abrEwmaFastLive: 0.5,
                    abrEwmaSlowLive: 6,
                    lowLatencyMode: false,
                    debug: {
                        trace: partial(hlsLogger, "trace"),
                        debug: partial(hlsLogger, "debug"),
                        log: partial(hlsLogger, "log"),
                        warn: partial(hlsLogger, "warn"),
                        info: partial(hlsLogger, "info"),
                        error: partial(hlsLogger, "error"),
                    },
                })
                this.hls.currentLevel = 0

                let streamUrl = `${AUDIO_STREAM_BASE_URL}/${this.broadcastIdentifier}/stream.m3u8`
                try {
                    await this._awaitStream(streamUrl)
                } catch (e) {
                    return [Promise.reject(e), Promise.reject(e)]
                }
                this.hls.loadSource(streamUrl)
                this.hls.attachMedia(this.audioSrcElement)

                this.hls.on(Hls.Events.FRAG_BUFFERED, (event, data) => {
                    this.networkStats.push({
                        trequest: data.frag.stats.loading.start,
                        tload: data.frag.stats.buffering.end,
                        bwEstimate: data.frag.stats.bwEstimate,
                    })
                })

                playPromise = new Promise((playRes, playRej) => {
                    this.audioSrcElement.play().catch(playRej)
                    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        playRes()
                    })
                    this.hls.on(Hls.Events.ERROR, async (event, data) => {
                        const errorData = data
                        if (errorData.fatal) {
                            if (data.details === "manifestLoadTimeOut" || data.details === "fragLoadTimeout") {
                                // Assume bad user network, attempt recovery
                                this.hls.startLoad()
                            } else {
                                switch (errorData.type) {
                                    case Hls.ErrorTypes.NETWORK_ERROR:
                                        console.error("fatal network error encountered, try to recover", errorData)
                                        Sentry.withScope((scope) => {
                                            scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                                            scope.setExtra("context", getHlsErrorContext(errorData))
                                            Sentry.captureMessage("Streaming network error")
                                        })
                                        this.hls?.startLoad()
                                        break
                                    case Hls.ErrorTypes.MEDIA_ERROR:
                                        Sentry.withScope((scope) => {
                                            scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                                            scope.setExtra("context", getHlsErrorContext(errorData))
                                            Sentry.captureMessage("Streaming media error")
                                        })
                                        this.hls?.recoverMediaError()
                                        break
                                    default:
                                        console.error("fatal streaming error", errorData)
                                        Sentry.withScope((scope) => {
                                            scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                                            scope.setExtra("context", getHlsErrorContext(errorData))
                                            Sentry.captureMessage("Streaming error")
                                        })
                                        this.hls?.destroy()
                                        playRej(data)
                                        stopRej(data)
                                        break
                                }
                            }
                        } else if (
                            errorData.type === Hls.ErrorTypes.MEDIA_ERROR &&
                            errorData.details === "bufferStalledError"
                        ) {
                            const deviceData = await this._getMediaDeviceChange()
                            Sentry.withScope((scope) => {
                                scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                                scope.setExtra("context", getHlsErrorContext(errorData))
                                scope.setExtra("mediaDevicesChanged", deviceData.changed)
                                scope.setExtra("mediaDevices", deviceData.devices)
                                Sentry.captureMessage("Streaming network glitch")
                            })
                        } else if (
                            errorData.type === Hls.ErrorTypes.NETWORK_ERROR &&
                            errorData.details === "levelLoadTimeOut"
                        ) {
                            const deviceData = await this._getMediaDeviceChange()
                            Sentry.withScope((scope) => {
                                scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                                scope.setExtra("context", getHlsErrorContext(errorData))
                                scope.setExtra("mediaDevicesChanged", deviceData.changed)
                                scope.setExtra("mediaDevices", deviceData.devices)
                                Sentry.captureMessage("Streaming network request timeout")
                            })
                        }
                    })
                })
            } else if (this.audioSrcElement.canPlayType("application/vnd.apple.mpegurl")) {
                playPromise = new Promise((playRes, playRej) => {
                    let metaLoaded = false
                    this.audioSrcElement.src = `${AUDIO_STREAM_BASE_URL}/${this.broadcastIdentifier}/stream.m3u8`
                    this.audioSrcElement.play()
                    eventCleanUp.push(
                        onEvent(
                            this.audioSrcElement,
                            "loadedmetadata",
                            () => {
                                metaLoaded = true
                                playRes()
                            },
                            { once: true }
                        )
                    )
                    eventCleanUp.push(
                        onEvent(
                            this.audioSrcElement,
                            "error",
                            (evt) => {
                                Sentry.withScope((scope) => {
                                    scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                                    scope.setExtra("sampleRate", this.audioCtx.sampleRate)
                                    Sentry.captureException(evt)
                                })
                                console.error(evt)
                                playRej(evt)
                            },
                            { once: true }
                        )
                    )
                    eventCleanUp.push(
                        onEvent(this.audioSrcElement, "waiting", (evt) => {
                            if (metaLoaded) {
                                // This is an audible buffer stall
                                Sentry.withScope((scope) => {
                                    scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
                                    scope.setExtra("sampleRate", this.audioCtx.sampleRate)
                                    Sentry.captureException(evt)
                                })
                                console.warn(evt)
                            }
                        })
                    )
                })
            }
        })

        stopPromise = stopPromise.finally(() => {
            for (let removal of eventCleanUp) {
                removal()
            }
        })

        return [playPromise, stopPromise]
    }

    async _getMediaDeviceChange() {
        let changed =
            isNumber(this.lastMediaDeviceChangeAt) &&
            this.lastMediaDeviceChangeAt > Date.now() - BUFFER_STALL_MEDIA_DEVICE_CHANGE_WAIT_PERIOD
        if (!changed) {
            changed = await Promise.race([
                sleep(BUFFER_STALL_MEDIA_DEVICE_CHANGE_WAIT_PERIOD).then(() => false),
                nextMediaDeviceChange().then(() => true),
            ])
        }
        const allDevices = await navigator.mediaDevices.enumerateDevices()
        const audioDevices = allDevices.filter((d) => d.kind === "audiooutput").map((d) => d.toJSON())
        return { changed, devices: audioDevices }
    }

    _monitorMediaDeviceChanges() {
        if (
            typeof navigator !== "undefined" &&
            typeof navigator.mediaDevices !== "undefined" &&
            navigator.mediaDevices.addEventListener
        ) {
            navigator.mediaDevices.addEventListener("devicechange", () => {
                this.lastMediaDeviceChangeAt = Date.now()
                Sentry.addBreadcrumb({
                    category: "streaming",
                    data: { mediaDeviceEvent: "devicechange" },
                })
            })
        }
    }

    dispose() {
        if (this.audioSrc) {
            this.audioSrc.disconnect()
            this.audioSrc = null
        }
        if (this.audioSrcElement) {
            this.audioSrcElement.pause()
            this.audioSrcElement.removeAttribute("src")
            this.audioSrcElement.load()
            this.audioSrcElement = null
        }
        if (this.hls) {
            this.hls.destroy()
        }
    }

    async _reportPauseIfNotEnding() {
        // After successfully started, monitor for pause events.
        // Pausing has been observed to happen on OS X Big Sur when a Bluetooth device is disconnected, and on mobile devices when another app/tab takes over audio.
        // However, a pause is also emitted just before "ended" when the stream ends, so we must detect if this was a non-end pause
        let ended = new Promise((res) =>
            this.audioSrcElement.addEventListener("ended", () => res(true), { once: true })
        )
        let didNotEnd = new Promise((res) => setTimeout(() => res(false), 500))
        let didEnd = await Promise.race([ended, didNotEnd])
        if (!didEnd && this.isPlaying && this.audioSrcElement) {
            console.log("paused, yet did not end, emitting")
            this.emit("externalPause")
        }
    }

    async _awaitStream(streamUrl) {
        let triesLeft = 30,
            networkError
        while (triesLeft-- > 0) {
            try {
                let res = await fetch(streamUrl, { method: "HEAD" })
                if (res.status === 200) {
                    return
                }
            } catch (e) {
                networkError = e
            }
            await new Promise((res) => setTimeout(res, 2000))
        }
        throw networkError || new Error(`Stream ${streamUrl} does not seem to exist`)
    }

    _reportNetworkStats() {
        if (this.networkStats.length === 0) return

        let fragmentsLoaded = 0,
            timeTotal = 0,
            timeMax = -Number.MAX_VALUE,
            timeMin = Number.MAX_VALUE,
            bwEstimateTotal = 0,
            bwEstimateMax = -Number.MAX_VALUE,
            bwEstimateMin = Number.MAX_VALUE

        while (this.networkStats.length > 0) {
            let { trequest, tload, bwEstimate } = this.networkStats.shift()
            let time = tload - trequest

            fragmentsLoaded++
            timeTotal += time
            timeMax = Math.max(timeMax, time)
            timeMin = Math.min(timeMin, time)
            bwEstimateTotal += bwEstimate
            bwEstimateMax = Math.max(bwEstimateMax, bwEstimate)
            bwEstimateMin = Math.min(bwEstimateMin, bwEstimate)
        }
        let timeMean = timeTotal / fragmentsLoaded
        let bwEstimateMean = bwEstimateTotal / fragmentsLoaded
        let stats = {
            fragmentsLoaded,
            timeMean,
            timeMin,
            timeMax,
            bwEstimateMean,
            bwEstimateMax,
            bwEstimateMin,
        }
        Sentry.withScope((scope) => {
            scope.setExtra("broadcastIdentifier", this.broadcastIdentifier)
            scope.setExtra("streamingStats", stats)
            Sentry.captureMessage("Streaming stats")
        })
    }
}

export function getHlsErrorContext(data) {
    switch (data.details) {
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
            return pick(data, "type", "details", "url", "reason")
        case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.AUDIO_TRACK_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.AUDIO_TRACK_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.FRAG_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.KEY_LOAD_ERROR:
            return pick(data, "type", "details", "url", "response")
        case Hls.ErrorDetails.KEY_LOAD_TIMEOUT:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
            return pick(data, "type", "details", "url")
        case Hls.ErrorDetails.FRAG_DECRYPT_ERROR:
            return pick(data, "type", "details", "reason")
        case Hls.ErrorDetails.FRAG_PARSING_ERROR:
            return pick(data, "type", "details", "reason")
        case Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR:
            return pick(data, "type", "details", "err", "mimeType")
        case Hls.ErrorDetails.BUFFER_APPEND_ERROR:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
            return pick(data, "type", "details", "buffer")
        case Hls.ErrorDetails.BUFFER_FULL_ERROR:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.BUFFER_SEEK_OVER_HOLE:
            return pick(data, "type", "details", "hole")
        case Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL:
            return pick(data, "type", "details")
        case Hls.ErrorDetails.REMUX_ALLOC_ERROR:
            return pick(data, "type", "details", "bytes", "reason")
        case Hls.ErrorDetails.LEVEL_SWITCH_ERROR:
            return pick(data, "type", "details", "level", "reason")
        case Hls.ErrorDetails.INTERNAL_EXCEPTION:
            return pick(data, "type", "details", "err")
        default:
            return data
    }
}

const onEvent = (target, evt, listener, options) => {
    target.addEventListener(evt, listener, options)
    return () => {
        target.removeEventListener(evt, listener)
    }
}

const nextMediaDeviceChange = () =>
    new Promise((res) => navigator.mediaDevices.addEventListener("devicechange", () => res(), { once: true }))

const sleep = (ms) => new Promise((res) => setTimeout(() => res(), ms))

export default JungAudioStreamPlayer
