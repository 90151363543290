import React, { useEffect, useContext, useRef, useState } from "react"
import classNames from "classnames"
import { Canvas } from "react-three-fiber"
import { TunnelPlaceholder } from "react-tunnels"

import { setupJung, JungContext } from "./JungContext"

import JungOrbVisualisation from "./JungOrbVisualisation"
import JungBlurVisualisation from "./JungBlurVisualisation"

import "./JungHTMLWrapper.scss"

const JungHTMLWrapper = ({ skipSetup }) => {
    let jungContext = useContext(JungContext)
    let [jungState, setJungState] = useState(jungContext.state)
    let wrapperRef = useRef()

    useEffect(() => {
        if (!skipSetup) setupJung()
    }, [skipSetup])

    useEffect(() => {
        let unSubFS = jungContext.onFullScreenChange((fullScreen) => {
            if (fullScreen) {
                let wrapper = wrapperRef.current
                if (wrapper.requestFullscreen) {
                    wrapper.requestFullscreen()
                } else {
                    wrapper.webkitRequestFullScreen()
                }
                function checkExit() {
                    if (!document.fullscreenElement) {
                        jungContext.setFullScreen(false)
                        wrapper.removeEventListener("fullscreenchange", checkExit)
                        wrapper.removeEventListener("webkitfullscreenchange", checkExit)
                    }
                }
                wrapper.addEventListener("fullscreenchange", checkExit)
                wrapper.addEventListener("webkitfullscreenchange", checkExit)
            }
        })
        let unSubState = jungContext.onStateChange(() => setJungState(jungContext.state))
        return () => {
            unSubFS()
            unSubState()
        }
    }, [jungContext])

    return (
        <>
            <div className={classNames("jung-wrapper", `state-${jungState}`)} ref={wrapperRef}>
                <TunnelPlaceholder id="jungButtonTunnel" />
                <Canvas
                    gl={{ alpha: false }}
                    pixelRatio={1}
                    className="jung-orb-visualisation"
                    style={{ position: "absolute" }}
                >
                    <JungOrbVisualisation />
                </Canvas>
                <JungBlurVisualisation />
            </div>
        </>
    )
}

export default JungHTMLWrapper
